'use client';

import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { PlaceholderSize } from '@/ts';
import styles from './styles/CommercialBoxPlaceholderCollapse.module.scss';

interface CommercialBoxPlaceholderCollapseProps {
  size: PlaceholderSize;
}

const CommercialBoxPlaceholderCollapse: React.FC<CommercialBoxPlaceholderCollapseProps> = ({ size }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setFadeOut(true); // start fading out when scrolling
      setTimeout(() => setIsVisible(false), 300); // optional, reset fade-out after animation
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!isVisible) return null;

  const sizeClass = size.toLowerCase(); // depending upon what is the size prop, sizeClass calculates height and set the css as per breakpoints

  return (
    <div
      className={`${styles.placeholder} ${styles[sizeClass]} ${fadeOut ? styles['fade-out'] : ''}`}
      data-testid="commercialBox-placeholder-collapse"
    >
      <Skeleton width="100%" height="100%" highlightColor="#8888880d" baseColor="#88888810" />
    </div>
  );
};

export default CommercialBoxPlaceholderCollapse;
