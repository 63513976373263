import type { VideoFloatAndParkData } from '@/ts';

interface FloatingProps {
  dismissible: boolean;
  mode: string;
}

function isEnabled(
  isLeadVideo: boolean,
  isDesktop: boolean | null,
  isAutoPlayEnabled: boolean,
  floatAndPark: VideoFloatAndParkData
) {
  const { desktopEnabled, mobileEnabled, onClickToPlayEnabled } = floatAndPark;
  const isAllowedBreakpoint = isDesktop ? desktopEnabled : mobileEnabled;
  return isLeadVideo && (isAutoPlayEnabled || onClickToPlayEnabled) && isAllowedBreakpoint;
}

export default function getFloatAndParkConfig(
  isLeadVideo: boolean,
  isDesktop: boolean | null,
  isAutoPlayEnabled: boolean,
  floatAndPark: VideoFloatAndParkData
): FloatingProps {
  const defaultFloatConfig = {
    dismissible: true,
  };
  let mode = 'notVisible';
  const floatModeAlwaysEnabled = false;
  if (isEnabled(isLeadVideo, isDesktop, isAutoPlayEnabled, floatAndPark)) {
    if (floatModeAlwaysEnabled) {
      mode = 'always';
    }
  } else {
    mode = 'never';
  }
  return {
    ...defaultFloatConfig,
    mode,
  };
}
