import type { CommercialBoxLocation } from '@/ts';

const boxNameMap: { [key in CommercialBoxLocation]: { [key: number | string]: string } } = {
  header: {
    1: 'above-header-box-1',
    2: 'masthead-webstrip',
  },
  gutter: {
    1: 'left-gutter',
    2: 'right-gutter',
  },
  'below-article': {
    1: 'below-article-box-1',
  },
  'sticky-bottom': {
    1: 'sticky-bottom-box',
  },
  aside: {
    1: 'rh-box-1',
    2: 'rh-box-2',
    3: 'rh-box-3',
    4: 'rh-box-4',
    5: 'rh-box-5',
    6: 'rh-box-6',
    7: 'rh-box-7',
    8: 'rh-box-8',
  },
  'below-aside': {
    1: 'rhc-box-last',
  },
  'article-body': {
    1: 'mid-article-box-1',
    2: 'mid-article-box-2',
    3: 'mid-article-box-3',
    4: 'mid-article-box-4',
    5: 'mid-article-box-5',
    6: 'mid-article-box-6',
    7: 'mid-article-box-7',
    8: 'mid-article-box-repeat',
    1000: 'mid-article-box-last',
  },
  'above-comment-section': {
    1: 'comment-box-1',
  },
  'comment-section': {
    1: 'comment-box-repeat',
  },
  video: {
    1: 'video-box',
  },
};

const generateCommercialBoxName = (location: CommercialBoxLocation, position: number): string => {
  let boxName = boxNameMap[location]?.[position] || '';
  if (location === 'article-body' && typeof position === 'number' && position > 7 && position !== 1000) {
    boxName = boxNameMap[location]?.[8] || '';
  } else if (location === 'comment-section') {
    boxName = boxNameMap[location]?.[1] || '';
  }

  return boxName;
};

export default generateCommercialBoxName;
