import useVideo from '@/store/video/video';
import { useEffect, useMemo, useRef } from 'react';
import type { JWPlayerInstance, VideoEventProps } from '@/ts';
import {
  hasAdImpressionData,
  hasErrorCodeData,
  hasEventItemData,
  hasEventTimeItemData,
  hasFloatData,
  hasFullScreenData,
} from '@/ts/guards/videoProps';
import { VIDEO_EVENTS } from '@/constants';
import { useAnalyticStore } from '@/store/analytics/analytics-provider';

/**
 * Custom Hook to handle side-effects of video tracking
 * @param eventName
 * @param event
 */

const useJWPlayerTracking = (
  () =>
  (
    playerInstance: JWPlayerInstance | null,
    playerId: string,
    videoId: string,
    videoTitle: string,
    interaction: string,
    playerPosition: string,
    datePublished: string,
    contentVertical: string,
    uploadedBy: string,
    clearance: string
  ) => {
    const videoStore = useVideo();
    const currentVideoId = useRef<string>(videoId);
    const currentVideoTitle = useRef<string>(videoTitle);
    const currentDuration = useRef<number>();
    const videoAdImpressionDuration = useRef<number>(0);
    const videoAdImpressionTimeLoading = useRef<number>(0);
    const videoIndex = useRef<number>(0);
    const videoError = useRef<number>(0);
    const videoDuration = useRef<number>(0);
    const videoMuted = useRef<string>('');
    const analyticsData = useAnalyticStore(({ state }) => state.client.analytics);
    // Subscribe to the analytics store to update analyticsData
    // useAnalytics.subscribe(({ state }) => {
    //   analyticsData = state.client.analytics;
    // });
    const dispatchEvent = useMemo(
      () => (eventName: string) => {
        videoDuration.current = playerInstance ? playerInstance.getDuration() : 0;
        videoMuted.current = playerInstance?.getMute() ? VIDEO_EVENTS.MUTE : VIDEO_EVENTS.UNMUTE;
        const eventObj: VideoEventProps = {
          articleAuthor: analyticsData?.articleAuthor,
          articleId: analyticsData?.articleId,
          articleType: analyticsData?.articleType || '',
          cleanUrl: analyticsData?.cleanUrl,
          event: eventName,
          headline: analyticsData?.headline,
          video_playerId: playerId,
          video_videoId: currentVideoId.current,
          video_title: currentVideoTitle.current,
          video_index: videoIndex.current,
          video_duration: videoDuration.current,
          video_muted: videoMuted.current,
          video_placement: playerPosition,
          video_initiateMethod: interaction,
          video_published_date: datePublished,
          video_content_vertical: contentVertical,
          video_uploaded_by: uploadedBy,
          video_clearance: clearance,
          video_ad_impression_duration: videoAdImpressionDuration.current,
          video_ad_impression_elapsed_time: videoAdImpressionTimeLoading.current,
          pageDomain: analyticsData?.pageDomain || '',
          pagescreenType: analyticsData?.pagescreenType || '',
          pageSection: analyticsData?.pageSection || '',
          pagesecondarySection: analyticsData?.pagesecondarySection || '',
          pagetertiarySection: analyticsData?.pagetertiarySection || '',
          primaryTag: analyticsData?.primaryTag,
        };
        if (eventName === VIDEO_EVENTS.VIDEO_ERROR) {
          eventObj.video_error = videoError.current;
        }
        videoStore.actions.addVideoEventToStore(eventObj);
      },
      [
        playerInstance,
        playerId,
        playerPosition,
        interaction,
        videoStore.actions,
        videoIndex,
        datePublished,
        contentVertical,
        uploadedBy,
        clearance,
      ]
    );

    useEffect(() => {
      if (!playerInstance) return;
      const percentageMap = {
        25: VIDEO_EVENTS.VIDEO_CONTENT_25,
        50: VIDEO_EVENTS.VIDEO_CONTENT_50,
        75: VIDEO_EVENTS.VIDEO_CONTENT_75,
        95: VIDEO_EVENTS.VIDEO_CONTENT_95,
        100: VIDEO_EVENTS.ENDED,
      };
      dispatchEvent(VIDEO_EVENTS.VIDEO_PLAYER_LOAD);
      videoIndex.current += 1;
      playerInstance.on('time', (event: unknown) => {
        if (hasEventTimeItemData(event)) {
          const { duration, position } = event;
          if (videoIndex.current && videoIndex.current === 1) {
            const progressPercentage = (position / duration) * 100;
            Object.entries(percentageMap).forEach(([percentage, message]) => {
              if (Math.floor(progressPercentage) === parseInt(percentage, 10)) {
                dispatchEvent(message);
              }
            });
          }
        }
      });
      playerInstance.on('beforePlay', () => {
        dispatchEvent(VIDEO_EVENTS.VIDEO_INITIATED);
      });
      playerInstance.on('adPlay', () => {
        dispatchEvent(VIDEO_EVENTS.VIDEO_AD_START);
      });
      playerInstance.on('adError', () => {
        dispatchEvent(VIDEO_EVENTS.VIDEO_AD_ERROR);
      });
      playerInstance.on('adPause', () => {
        dispatchEvent(VIDEO_EVENTS.AD_PAUSE);
      });
      playerInstance.on('adSkipped', () => {
        dispatchEvent(VIDEO_EVENTS.AD_SKIPPED);
      });
      playerInstance.on('adImpression', (event: unknown) => {
        if (hasAdImpressionData(event)) {
          const { duration, timeLoading } = event;
          videoAdImpressionDuration.current = duration;
          videoAdImpressionTimeLoading.current = timeLoading;
        }
      });
      playerInstance.on('adComplete', () => {
        dispatchEvent(VIDEO_EVENTS.VIDEO_AD_COMPLETE);
      });
      playerInstance.on('firstFrame', () => {
        dispatchEvent(VIDEO_EVENTS.VIDEO_CONTENT_START);
      });
      playerInstance.on('complete', () => {
        dispatchEvent(VIDEO_EVENTS.VIDEO_CONTENT_COMPLETE);
      });
      playerInstance.on('pause', () => {
        dispatchEvent(VIDEO_EVENTS.PAUSE);
      });
      playerInstance.on('play', () => {
        dispatchEvent(VIDEO_EVENTS.PLAY);
      });
      playerInstance.on('error', (event: unknown) => {
        if (hasErrorCodeData(event)) {
          const { code } = event;
          videoError.current = code;
        }
        dispatchEvent(VIDEO_EVENTS.VIDEO_ERROR);
      });
      playerInstance.on('fullscreen', (event: unknown) => {
        if (hasFullScreenData(event)) {
          dispatchEvent(VIDEO_EVENTS.ENTER_FULL_SCREEN);
        }
      });
      // @ts-ignore-eslint/ban-ts-comment
      playerInstance.on('float', (event: unknown) => {
        if (hasFloatData(event)) {
          dispatchEvent(VIDEO_EVENTS.VIDEO_CLOSE_FLOAT_AND_PARK);
        }
      });
      playerInstance.on('playlistItem', (event: unknown) => {
        if (hasEventItemData(event)) {
          const { mediaid, duration, title } = event.item;
          currentVideoId.current = mediaid;
          currentDuration.current = duration;
          currentVideoTitle.current = title;
          videoIndex.current += 1;
        }
      });
    }, [playerInstance]);
  }
)();
export default useJWPlayerTracking;
