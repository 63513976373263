import { VendorName } from '@/commercial/types';

const AUCTION_VENDORS: VendorName[] = [
  'amazonAdvertising',
  'magnite',
  'xandr',
  'ozone',
  'criteo',
  'gumgum',
  'vidazoo',
  'ix',
  'richaudience',
];

export default AUCTION_VENDORS;
