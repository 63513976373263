import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { AffiliateStickyContentData, AffiliateStickyContentState } from '@/commercial/types';
import { initialAffiliateStickyContentState } from '../assets/initialStates';

export interface AffiliateStickyContentStore {
  state: AffiliateStickyContentState;
  actions: {
    addAffiliateStickyContentToStore: (payload: { affiliateStickyContent: AffiliateStickyContentData | null }) => void;
  };
}

const useAffiliateStickyContentStore = create<AffiliateStickyContentStore>()(
  devtools(
    (set) => ({
      state: { ...initialAffiliateStickyContentState },
      actions: {
        addAffiliateStickyContentToStore: (payload: { affiliateStickyContent: AffiliateStickyContentData | null }) => {
          set((currentState) => ({
            state: {
              ...currentState.state,
              server: {
                ...currentState.state.server,
                ...payload,
              },
            },
          }));
        },
      },
    }),
    {
      name: 'affiliateStickyContent',
    }
  )
);

export default useAffiliateStickyContentStore;
