import { GPTTargetingMap } from '@/commercial/types';
import { OutstreamAuctionTargetingStore } from './outstreamAuctionTargeting';

export const hasOzoneOutstream = (auctionTargetingStore: OutstreamAuctionTargetingStore): boolean => {
  const outstreamSlot = 'div-gpt-ad-vip-slot';
  const { outstreamTargetingData } = auctionTargetingStore.state.client;
  const outstreamSlotConfig = outstreamTargetingData[outstreamSlot];

  return outstreamSlotConfig?.oz_ozpubmv_vid === 'outstream';
};

export const hasOutstreamAuctionTargetingComplete = (
  auctionTargetingStore: OutstreamAuctionTargetingStore
): boolean => {
  const { isOutstreamAuctionTargetingComplete } = auctionTargetingStore.state.client;
  return isOutstreamAuctionTargetingComplete;
};

export const getOutstreamTargetingData = (
  auctionTargetingStore: OutstreamAuctionTargetingStore
): Record<string, GPTTargetingMap> => {
  const { outstreamTargetingData } = auctionTargetingStore.state.client;
  return outstreamTargetingData;
};
