import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { initialCommercialFeatureControlState } from '@/store/assets/initialStates';
import { CommercialFeatureControlState, CommercialFeatureControlData } from '@/commercial/types';
import log from '@/helpers/utils/log';
import getDirFileName from '@/helpers/utils/dirPath';

export interface CommercialFeatureControlStore {
  state: CommercialFeatureControlState;
  actions: {
    /**
     * Updates the store's server property
     */
    updateCommercialFeatureControlStore: (payload: CommercialFeatureControlData) => void;
  };
}

const useCommercialFeatureControl = create<CommercialFeatureControlStore>()(
  devtools(
    (set) => ({
      state: { ...initialCommercialFeatureControlState },
      actions: {
        updateCommercialFeatureControlStore: (payload) => {
          log().info(`${getDirFileName(import.meta.url)} Updating commercial feature control state:`, payload);
          set((currentState) => ({
            state: {
              ...currentState.state,
              server: {
                ...currentState.state.server,
                ...payload,
              },
            },
          }));
        },
      },
    }),
    {
      name: 'commercialFeatureControl',
    }
  )
);

export default useCommercialFeatureControl;
