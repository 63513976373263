'use client';

import styled, { css } from 'styled-components';
import { BREAKPOINTS, breakpoints } from '@/theme';
import type { SlotType } from '@/ts';

interface Props {
  $type: SlotType;
  $inArticleSlot: boolean;
  $isStickyBottom: boolean;
  $isTopSlot: boolean;
  $collapsable: boolean;
  $isRhc: boolean;
}

const cssVarsForTopSlot = {
  // BART positions
  bartRightOffsetLarge: '-118px',
  bartTopLarge: '240px',
  bartTopXLarge: '235px',
  bartWidth: '25px',

  // override gpt-ad-menu positions
  gptAdMenuTopLarge: '-230px',
  gptAdMenuLeft: '-50px',
  gptAdMenuTopXLarge: '-170px',

  // AdCode positions
  adCodeRightOffsetLarge: '-95px',
  adCodeTopLarge: '160px',
  adCodeTopXLarge: '150px',

  // Rotation Angles
  rotateVertical: '270deg', // rotate wrapper to a vertical position
  gptAdMenuCounterRotate: '-270deg', // Counter rotation to neturalize parent's rotation
  TranslateYForCentering: '50%',
};

const getStyle = ({ $inArticleSlot, $isTopSlot }: Props) => {
  const cssStyle = css`
    display: flex;
    flex-direction: row;
    justify-content: ${!$inArticleSlot || !$isTopSlot ? 'space-between' : 'unset'};
    margin-left: ${!$isTopSlot ? 'auto' : 'unset'};
    width: ${$isTopSlot ? '100%' : 'unset'};
  `;

  return cssStyle;
};

const stickyBottom = css`
  ${breakpoints.medium} {
    display: none;
  }
`;

const getAdvertisementLabel = (isStickyTop: boolean, $inArticleSlot: boolean) => {
  let cssStyle = css``;
  if (isStickyTop) {
    cssStyle = css`
      content: '';
    `;
  } else if (!$inArticleSlot && !isStickyTop) {
    cssStyle = css`
      content: 'AD';
    `;
  } else {
    cssStyle = css`
      content: 'AD';

      ${breakpoints.medium} {
        content: 'ADVERTISEMENT';
      }
    `;
  }
  return cssStyle;
};

export const ArticleIndicator = styled.span`
  color: var(--color-sem-text-neutral-bold);
  font-size: var(--text-typeset-font-size-xs);
  font-weight: var(--text-typeset-font-weights-normal-400);
  letter-spacing: var(--text-typeset-letter-spacing-normal);
  line-height: calc(var(--size-ref-10) - var(--border-width-ref-2));
`;

const alignTopSlotForAdInfo = css`
  ${breakpoints.large} {
    display: inline-block;
    left: -50px;
    position: fixed;
    top: 140px;
    transform: translateY(-50%) rotate(270deg);
  }

  ${breakpoints.xlarge} {
    display: inline-block;
    left: -85px;
    position: relative;
    top: 120px;
    transform: rotate(270deg);
  }
`;

export const AdInfo = styled.div<Props>`
  ${(props) => getStyle(props)}

  &::before {
    color: var(--color-sem-text-neutral-default);
    font-size: var(--text-typeset-font-size-2xs);
    font-weight: var(--text-typeset-font-weights-normal-600);
    letter-spacing: var(--text-typeset-letter-spacing-normal);
    line-height: calc(var(--size-ref-10) - var(--border-width-ref-2));
    ${({ $type, $isTopSlot, $inArticleSlot }) => $type === 'gpt' && getAdvertisementLabel($isTopSlot, $inArticleSlot)};
    ${({ $isTopSlot }) => $isTopSlot && alignTopSlotForAdInfo};
  }
`;

export const BARTIcon = styled.div<Props>`
  ${(props: Props) => {
    const {
      bartRightOffsetLarge,
      bartTopLarge,
      bartTopXLarge,
      bartWidth,
      gptAdMenuTopLarge,
      gptAdMenuLeft,
      gptAdMenuTopXLarge,
      gptAdMenuCounterRotate,
      rotateVertical,
      TranslateYForCentering,
    } = cssVarsForTopSlot;
    let styles = css``;
    if (props.$collapsable) {
      styles = css`
        position: relative;
        top: 66px;
        left: 15px;
        width: ${bartWidth};

        .gpt-ad-menu {
          top: -63px;
          right: 50px;
        }
      `;
    } else if (props.$isTopSlot) {
      styles = css`
        ${breakpoints.small} {
          width: 100%;
        }
        ${breakpoints.large} {
          display: inline-block;
          position: fixed;
          right: calc(100vw - 100% - ${bartRightOffsetLarge});
          top: ${bartTopLarge};
          transform: translateY(-${TranslateYForCentering}) rotate(${rotateVertical});
          padding: 0;
          width: ${bartWidth};

          .gpt-ad-menu {
            transform: translateY(${TranslateYForCentering}) rotate(${gptAdMenuCounterRotate});
            top: ${gptAdMenuTopLarge};
            left: ${gptAdMenuLeft};
          }
        }

        ${breakpoints.xlarge} {
          display: inline-block;
          left: 8px;
          position: relative;
          top: ${bartTopXLarge};
          transform: rotate(${rotateVertical});
          padding: 0;
          width: ${bartWidth};

          .gpt-ad-menu {
            transform: rotate(${gptAdMenuCounterRotate});
            top: ${gptAdMenuTopXLarge};
            left: ${gptAdMenuLeft};
          }
        }
      `;
    }

    if (!props.$collapsable) {
      styles = css`
        ${styles}
        padding-left: 20px;
        padding-top: 6px;
      `;
    }
    return styles;
  }}
`;

const adCodeStyle = (isCollapsable: boolean) => {
  let alignTopSlotForAdCode = css``;
  if (isCollapsable) {
    alignTopSlotForAdCode = css`
      ${breakpoints.large} {
        position: relative;
        top: 60px;
      }
    `;
  } else {
    alignTopSlotForAdCode = css`
      ${breakpoints.large} {
        display: inline-block;
        position: fixed;
        right: calc(100vw - 100% - ${cssVarsForTopSlot.adCodeRightOffsetLarge});
        top: ${cssVarsForTopSlot.adCodeTopLarge};
        transform: translateY(-${cssVarsForTopSlot.TranslateYForCentering}) rotate(${cssVarsForTopSlot.rotateVertical});
      }

      ${breakpoints.xlarge} {
        display: inline-block;
        left: 66px;
        position: relative;
        top: 150px;
        transform: rotate(${cssVarsForTopSlot.rotateVertical});
      }
    `;
  }
  return alignTopSlotForAdCode;
};

export const AdCode = styled.div<Props>`
  color: var(--color-sem-text-neutral-disabled);
  font-size: var(--text-typeset-font-size-2xs);
  font-weight: var(--text-typeset-font-weights-normal-600);
  height: ${({ $isTopSlot }) => ($isTopSlot ? '0' : `var(--size-ref-10)`)};
  letter-spacing: var(--text-typeset-letter-spacing-normal);
  line-height: calc(var(--size-ref-10) - var(--border-width-ref-2));
  ${({ $isTopSlot, $collapsable }) => $isTopSlot && adCodeStyle($collapsable)};
  padding-left: var(--size-ref-4);
`;

const topSlotWidth = { l: '970px' };

const widthMixin = css<Props>`
  ${(props) => {
    let style;
    if (props.$isTopSlot) {
      style = css`
        @media (min-width: ${BREAKPOINTS.l}px) {
          width: ${topSlotWidth.l};
        }
      `;
    }
    return style;
  }};
`;

const heightMixin = css<Props>`
  ${(props) => {
    let style;
    if (props.$isTopSlot) {
      style = css`
        @media (min-width: ${BREAKPOINTS.s}px) {
          height: var(--size-ref-10);
        }
        @media (min-width: ${BREAKPOINTS.l}px) {
          height: 0;
        }
      `;
    } else {
      style = css`
        height: var(--size-ref-10);
      `;
    }
    return style;
  }};
`;

const CommercialLabelStyle = styled.div<Props>`
  display: flex;
  flex-direction: row;
  height: ${({ $isTopSlot }) => ($isTopSlot ? '0' : `var(--size-ref-10)`)};
  justify-content: space-between;
  ${({ $inArticleSlot }) =>
    $inArticleSlot && 'border-top: var(--border-width-ref-2) solid var(--color-sem-border-neutral-subtle)'};
  ${({ $isStickyBottom }) => $isStickyBottom && stickyBottom}
  ${({ $isTopSlot }) => $isTopSlot && 'border: none'};
  ${({ $isTopSlot }) => $isTopSlot && 'float: right'};
  ${heightMixin};
  ${widthMixin};
`;

export default CommercialLabelStyle;
