'use client';

/**
 * "CommercialBox" is a container for all types of commercial content, such as banner ads etc.
 *
 * It also handles "fallback" in cases where a certain type of content may be deemed to be
 * not good enough - for example in non-GDPR geo-regions we might want to show ads from
 * Taboola instead of Google.
 */

import { useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import type {
  CommercialGPTProps,
  ConverseConfigProps,
  MantisConfigProps,
  PrimisConfigProps,
  TaboolaConfigProps,
} from '@/commercial/types';
import type { CommercialBoxProps } from '@/ts';
import useCommercialBoxContent from '@/hooks/useCommercialBoxContent';
import CommercialGPT from '@/components/atoms/CommercialGPT/CommercialGPT';
import PrimisPlayer from '@/components/molecules/PrimisPlayer/PrimisPlayer';
import SkinAd from '@/components/atoms/SkinAd/SkinAd';
import getHeights from '@/helpers/utils/getCommercialBoxHeights';
import checkStickyBottom from '@/helpers/utils/checkStickyBottom';
import useCommercialFeatureControl from '@/store/commercialFeatureControl/commercialFeatureControl';
import checkTopSlot from '@/helpers/utils/checkTopSlot';
import generateCommercialBoxName from '@/helpers/commercial/generateCommercialBoxName';
import CommercialTaboola from '@/components/atoms/CommercialTaboola/CommercialTaboola';
import MantisCarousel from '@/components/atoms/Mantis/MantisCarousel';
import CommercialLabel from '@/components/atoms/CommercialLabel/CommercialLabel';
import CommercialConverse from '@/components/atoms/CommercialConverse/CommercialConverse';
import CommercialTopSlotStickyCounter from '@/components/atoms/CommercialTopSlotCounter/CommercialTopSlotStickyCounter';
import useGptSlots from '@/store/gptSlots/gptSlots';
import { isSlotCollapsable } from '@/store/gptSlots/selectors';
import CollapseCommercialGPT from '@/components/atoms/CommercialGPT/CollapseCommercialGPT';
import useCommercialBoxAggregation from '@/hooks/useCommercialBoxAggregation';
import { getCommercialControlFeatures } from '@/store/commercialFeatureControl/selectors';
import MastheadWebstrip from '@/components/atoms/MastheadWebstrip/MastheadWebstrip';
import CommercialOutstreamAuction from '@/components/atoms/CommercialOutstreamAuction/CommercialOutstreamAuction';
import CommercialBoxCollapsePlaceholder from '@/components/atoms/CommercialBoxPlaceholderCollapse/CommercialBoxPlaceholderCollapse';
import useCustomAdFormats from '@/store/customAd/customAdFormats';
import { getCustomFooterAdHeight, checkCustomFooterAd } from '@/store/customAd/selectors';
import CommercialBoxStyle from './styles/CommercialBoxStyle';
import CommercialBoxChildStyle from './styles/CommercialBoxChildStyle';
import ErrorBoundaryFallback from '../ErrorBoundaryFallback/ErrorBoundaryFallback';
import CommercialBoxPlaceholderStyle from './styles/CommercialBoxPlaceholderStyle';
import JWPlayer from '../JWPlayer/JWPlayer';

const CommercialBox: React.FC<CommercialBoxProps> = ({
  setPlaceholderHeight,
  location,
  position,
  videoData,
  hide = false,
}) => {
  const boxName = generateCommercialBoxName(location, position);
  const gptSlotsStore = useGptSlots();
  const customAdStore = useCustomAdFormats();
  const commercialBoxContent = useCommercialBoxContent(boxName, position, location, videoData);
  const commBoxRef = useRef<HTMLDivElement>(null);
  const isStickyTop = !!commercialBoxContent && checkTopSlot(commercialBoxContent.childProps.slotId);
  const commercialFeatureControlStore = useCommercialFeatureControl();
  const { disableCommercial } = getCommercialControlFeatures(commercialFeatureControlStore);
  const stickyClassname = isStickyTop ? 'sticky' : 'commercial-box-style';
  const hasCustomFooterAd = checkCustomFooterAd(customAdStore);
  const customFooterAdHeight = getCustomFooterAdHeight(customAdStore);

  useCommercialBoxAggregation(
    boxName,
    location,
    position,
    commercialBoxContent?.childName,
    commercialBoxContent?.childProps.slotId
  );

  if (disableCommercial || commercialBoxContent === null) {
    return null;
  }

  const { childName, childProps, commercialBoxChildStyleProps, size: placeholderSize } = commercialBoxContent;
  const { size, slotId, useAdvertLabel } = childProps;

  const isCollapsableAndNotCollapsed = isSlotCollapsable(slotId, true)(gptSlotsStore);

  if (isCollapsableAndNotCollapsed) {
    CollapseCommercialGPT(slotId, commBoxRef, gptSlotsStore);
  }

  if (checkStickyBottom(slotId) && setPlaceholderHeight) {
    // adds commercial label height --molecule-ace-signposting-container-height: 36px
    const commercialBoxHeight = useAdvertLabel ? Math.max(...getHeights(size)) + 36 : Math.max(...getHeights(size));
    setPlaceholderHeight('CommercialBox', commercialBoxHeight);
    if (hasCustomFooterAd && customFooterAdHeight > 0) {
      setPlaceholderHeight('CustomFooterAd', customFooterAdHeight);
      setPlaceholderHeight('CommercialBoxRemove', -commercialBoxHeight);
    }
  }

  const label =
    !hide && useAdvertLabel && !isStickyTop && !!childName ? (
      <CommercialLabel location={location} type={childName} slotId={slotId} />
    ) : null;

  let atomic;
  // Ensure that each child component exposes the 'shouldFallback' function

  switch (childName) {
    case 'gpt':
      atomic = (
        <CommercialBoxStyle
          ref={commBoxRef}
          className={stickyClassname}
          $location={location}
          $position={position}
          data-testid={`commercial-${boxName}`}
          style={
            hasCustomFooterAd && location === 'sticky-bottom'
              ? { visibility: 'hidden', height: `${customFooterAdHeight}px`, display: 'block' }
              : undefined
          }
        >
          {isStickyTop && <CommercialTopSlotStickyCounter topslotRef={commBoxRef} showCounter={true} />}
          <CommercialBoxChildStyle {...commercialBoxChildStyleProps} className="commercial-box-child-style">
            {isStickyTop && <CommercialLabel location={location} type={childName} slotId={slotId} />}
            <CommercialGPT {...(childProps as CommercialGPTProps)} />
          </CommercialBoxChildStyle>
        </CommercialBoxStyle>
      );
      break;
    case 'taboola':
      atomic = (
        <CommercialBoxStyle $location={location} data-testid={`commercial-${boxName}`}>
          <CommercialBoxChildStyle {...commercialBoxChildStyleProps}>
            <CommercialTaboola {...(childProps as TaboolaConfigProps)} />
          </CommercialBoxChildStyle>
        </CommercialBoxStyle>
      );
      break;
    case 'primis':
      atomic = (
        <CommercialBoxStyle $location={location} data-testid={`commercial-${boxName}`}>
          <CommercialBoxChildStyle {...commercialBoxChildStyleProps}>
            <PrimisPlayer {...(childProps as PrimisConfigProps)} />
          </CommercialBoxChildStyle>
        </CommercialBoxStyle>
      );
      break;
    case 'jwplayer':
      atomic = <JWPlayer {...childProps.videoData} />;
      break;
    case 'skinAd':
      atomic =
        location === 'gutter' ? (
          <SkinAd position={position} /> // Skin sides (left | right) renders in a Gutter and Gutter have different styling, hence <SkinAd> for sides does not use <CommercialBoxStyle> and <CommercialBoxChildStyle>
        ) : (
          <CommercialBoxStyle
            ref={commBoxRef}
            className={stickyClassname}
            $location={location}
            $position={position}
            data-testid={`commercial-${boxName}`}
          >
            {isStickyTop && <CommercialTopSlotStickyCounter topslotRef={commBoxRef} showCounter={false} />}
            <CommercialBoxChildStyle {...commercialBoxChildStyleProps} className="commercial-box-child-style">
              <SkinAd />
            </CommercialBoxChildStyle>
          </CommercialBoxStyle>
        );
      break;
    case 'mastheadWebstripAd':
      atomic = <MastheadWebstrip data-testid={`commercial-${boxName}`} />;
      break;
    case 'mantisCarousel':
      if (!hide) {
        atomic = (
          <CommercialBoxStyle $location={location} data-testid={`commercial-${boxName}`}>
            <CommercialBoxChildStyle {...commercialBoxChildStyleProps}>
              <MantisCarousel {...(childProps as MantisConfigProps)} />
            </CommercialBoxChildStyle>
          </CommercialBoxStyle>
        );
      }
      break;
    case 'converse':
      atomic = (
        <CommercialBoxStyle $location={location} data-testid={`commercial-${boxName}`}>
          <CommercialBoxChildStyle {...commercialBoxChildStyleProps}>
            <CommercialConverse {...(childProps as ConverseConfigProps)} />
          </CommercialBoxChildStyle>
        </CommercialBoxStyle>
      );
      break;
    case 'placeholder':
      atomic = (
        <CommercialBoxPlaceholderStyle $size={placeholderSize || ''}>
          <Skeleton width="100%" height="100%" highlightColor="#8888880d" baseColor="#88888810" />
        </CommercialBoxPlaceholderStyle>
      );
      break;
    case 'placeholderWithAuction':
      atomic = (
        <CommercialBoxPlaceholderStyle $size={placeholderSize || ''}>
          <Skeleton width="100%" height="100%" highlightColor="#8888880d" baseColor="#88888810" />
          <CommercialOutstreamAuction {...childProps}></CommercialOutstreamAuction>
        </CommercialBoxPlaceholderStyle>
      );
      break;
    case 'placeholderCollapse':
      atomic = <CommercialBoxCollapsePlaceholder size={placeholderSize || ''} />;
      break;
    default:
      atomic = null;
  }

  return (
    <ErrorBoundaryFallback>
      {label}
      {atomic}
    </ErrorBoundaryFallback>
  );
};

export default CommercialBox;
