'use client';

import { getHydra } from '@/commercial/helpers/hydra';
import { GPTTargetingMap } from '@/commercial/types';
import useAdsConfig from '@/store/adsConfig/adsConfig';
import { getHydraSlotConfig } from '@/store/adsConfig/selectors';
import { consentSignalReady, isVendorConsented } from '@/store/consent/selectors';
import useOutstreamAuctionTargeting from '@/store/outstreamAuctionTargeting/outstreamAuctionTargeting';
import { CommercialBoxChildProps } from '@/ts';
import { useEffect } from 'react';
import useConsentData from '@/store/consent/consent';
import { getHydraFeatureFlag } from '@/store/hydra/selectors';
import useHydra from '@/store/hydra/hydra';
import AUCTION_VENDORS from '@/constants/auctionVendors';

const CommercialOutstreamAuction: React.FC<CommercialBoxChildProps> = ({ slotId }) => {
  const { addOutstreamAuctionTargetingToStore, setOutstreamAuctionTargetingComplete } =
    useOutstreamAuctionTargeting().actions;
  const consentStore = useConsentData();
  const hydraStore = useHydra();

  const cmpReady: boolean = consentSignalReady(consentStore);
  const consentChecks = AUCTION_VENDORS.map((v) => isVendorConsented(v));
  const allVendorsConsented = consentChecks.every((checker) => checker(consentStore));
  const hydraEnabled = getHydraFeatureFlag(hydraStore);
  useEffect(() => {
    const triggerAuction = async (): Promise<void> =>
      new Promise((resolve) => {
        if (hydraEnabled && allVendorsConsented) {
          const hydra = getHydra();
          const adsConfigStore = useAdsConfig.getState();
          const slotConfig = getHydraSlotConfig([slotId])(adsConfigStore);

          const AUCTION_TIMEOUT = 2000;
          // Promise resolves even if Hydra takes too long
          const auctionTimeout = setTimeout(resolve, AUCTION_TIMEOUT + 100);

          try {
            hydra.push({
              cmd: 'trigger auction',
              slots: slotConfig,
              timeout: AUCTION_TIMEOUT,
              callback: async (auctionTargetingData: Record<string, GPTTargetingMap>) => {
                clearTimeout(auctionTimeout); // Clear timeout if Hydra completes in time
                addOutstreamAuctionTargetingToStore(auctionTargetingData);
                setOutstreamAuctionTargetingComplete(true);
                resolve();
              },
            });
          } catch (error) {
            console.error('Hydra Error:', error);
            clearTimeout(auctionTimeout);
            setOutstreamAuctionTargetingComplete(true);
            resolve();
          }
        } else if (!hydraEnabled && cmpReady) {
          setOutstreamAuctionTargetingComplete(true);
          resolve();
        } else {
          resolve();
        }
      });

    triggerAuction();
  }, [allVendorsConsented, hydraEnabled, cmpReady]);

  return null;
};

export default CommercialOutstreamAuction;
