'use client';

import React, { useEffect, useRef } from 'react';
import type { ConverseConfigProps } from '@/commercial/types';
import useConverse from '@/store/converse/converse';
import { getHtmlTemplate } from '@/store/converse/selectors';
import { CommercialStickyWithinSlotStyle, CommercialStyle } from './styles/CommercialConverseStyle';

const loadConverseContent = (shadowRoot: ShadowRoot, htmlTemplate: string) => {
  const container = document.createElement('div');
  container.id = 'converse-wrapper';
  shadowRoot.appendChild(container);
  const range = document.createRange();
  range.selectNode(container);
  const documentFragment = range.createContextualFragment(htmlTemplate);
  container.appendChild(documentFragment);
};

const addListeners = (shadowRoot: ShadowRoot) => {
  window.addEventListener('init-converse-component', () => {
    const cnvrse = window?.converse;
    if (cnvrse?.loader) {
      cnvrse.loader(shadowRoot);
      if (cnvrse.tracking?.trackWidgetLoaded) {
        cnvrse.tracking.trackWidgetLoaded();
      }
    }
  });
};

const CommercialConverse: React.FC<ConverseConfigProps> = ({ slotId }) => {
  const converseData = useConverse();
  const htmlTemplate = getHtmlTemplate(converseData);
  const shadowHost = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shadowHost.current && !!htmlTemplate) {
      const shadowRoot = shadowHost.current.attachShadow({ mode: 'open' });
      addListeners(shadowRoot);
      loadConverseContent(shadowRoot, htmlTemplate);
      const cnvrse = window?.converse;
      if (cnvrse?.params && cnvrse?.initTracking) {
        cnvrse.params.allowTracking = true;
        cnvrse.initTracking(shadowRoot.querySelector('#converse-wrapper'));
      }
    }
  }, [htmlTemplate]);

  return (
    <>
      <CommercialStyle>
        <CommercialStickyWithinSlotStyle>
          <div id={slotId} ref={shadowHost} data-testid={'converse-widget'}></div>
        </CommercialStickyWithinSlotStyle>
      </CommercialStyle>
    </>
  );
};
export default CommercialConverse;
