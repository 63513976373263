'use client';

import styled from 'styled-components';

export const CommercialStyle = styled.div`
  height: 100%;
`;

export const CommercialStickyWithinSlotStyle = styled.div`
  position: sticky;
  top: 150px;
`;
