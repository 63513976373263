import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { CountryCodeState, UpperTwoLetter } from '@/commercial/types';

export interface CountryCodeStore {
  state: CountryCodeState;
  actions: {
    addCountryCodeToStore: (countryCode: UpperTwoLetter) => void;
  };
}

export const initialCountryCodeState: CountryCodeState = {
  server: {
    countryCode: 'XX',
  },
};

const useCountryCode = create<CountryCodeStore>()(
  devtools(
    (set) => ({
      state: { ...initialCountryCodeState },
      actions: {
        addCountryCodeToStore: (countryCode: UpperTwoLetter) => {
          set(() => ({
            state: {
              server: {
                countryCode,
              },
            },
          }));
        },
      },
    }),
    {
      name: 'countryCode',
    }
  )
);

export default useCountryCode;
