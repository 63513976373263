'use client';

import getDirFileName from '@/helpers/utils/dirPath';
import log from '@/helpers/utils/log';
import { FunctionComponent, ReactElement, Component } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

interface ErrorFixProps extends React.PropsWithChildren {
  fallback?: ReactElement<unknown, string | FunctionComponent | typeof Component> | null;
}

const ErrorBoundaryFallback: React.FC<ErrorFixProps> = ({ fallback = <></>, children }) => {
  const logErrorBoundary = (error: Error) => {
    const fileDirectory = getDirFileName(import.meta.url);
    log().error({ message: `${fileDirectory} error boundary triggered`, error });
  };

  return (
    <ErrorBoundary fallback={fallback} onError={logErrorBoundary}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryFallback;
