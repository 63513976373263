import useAdsConfig from '@/store/adsConfig/adsConfig';
import { getGPTConfigById } from '@/store/adsConfig/selectors';
import useCommercialBoxAggregationStore from '@/store/commercialBoxAggregation/commercialBoxAggregation';
import useCommercialBoxesConfig from '@/store/commercialBoxesConfig/commercialBoxesConfig';
import getCommercialBoxConfigByBoxName from '@/store/commercialBoxesConfig/selectors';

import type { CommercialBoxLocation, BoxAggregationProps, SlotType } from '@/ts';

// list of boxes on page, each box contains data about the slot that will be displayed in it
const boxesList: Record<string, BoxAggregationProps> = {};
// Dynamically loaded box names on the client side that do not require inclusion in SRA
const dynamicBoxes = ['comment-box-repeat', 'mid-article-box-1'];

const checkAggregationComplete = (): boolean => {
  const sraBoxes = Object.values(boxesList).filter((box) => box.isSraSlot);
  return !!sraBoxes.length && sraBoxes.every((box) => box.isResolved && box.slotType === 'gpt');
};

const checkDynamicBox = (boxName: string) => dynamicBoxes.includes(boxName);

/**
 * check if the box currently contains either:
 *   a) a gpt sra/ibto slot, or
 *   b) a placeholder that could potentially resolve into a gpt sra/ibto slot
 */
const checkSraSlot = (boxName: string, slotType: SlotType, slotId: string): boolean => {
  const commercialBoxesConfig = useCommercialBoxesConfig.getState();
  const boxConfig = getCommercialBoxConfigByBoxName(boxName)(commercialBoxesConfig);
  const adsConfigStore = useAdsConfig.getState();

  let isSRABox = false;
  if (slotType === 'gpt') {
    const gptSlotConfig = getGPTConfigById(slotId)(adsConfigStore);
    isSRABox = gptSlotConfig?.isEligibleForIBTO || false;
  } else if (slotType === 'placeholder' && ['placeholder', 'placeholder-gpt'].includes(slotId)) {
    const potentialGPTSlot = boxConfig.find((cfg) => cfg.slotType === 'gpt');
    if (potentialGPTSlot) {
      const gptSlotConfig = getGPTConfigById(potentialGPTSlot.slotId)(adsConfigStore);
      isSRABox = gptSlotConfig?.isEligibleForIBTO || false;
    }
  }

  return isSRABox;
};

/**
 * useCommercialBoxAggregation is a custom hook that's called by CommercialBox.
 *   It's job is to maintain a list of all the CommercialBoxes, including:
 *     - whether a decision hs been reached on what kind of commercial content the box should resolve to,
 *     - whether the box should be involved in the GooglePublisherTag SingleRequestArchitechture initial request.
 *   It then writes this information to boxAggregationStore for others to consume.
 * @param boxName: string
 * @param location: CommercialBoxLocation
 * @param position: number
 * @param slotType: SlotType | undefined
 * @param slotId: string
 */
const useCommercialBoxAggregation = (
  boxName: string,
  location: CommercialBoxLocation,
  position: number,
  slotType: SlotType | undefined,
  slotId: string
): void => {
  if (!boxName) {
    return;
  }
  const boxId = location.concat('-', position.toString());

  // early return when chooseBoxComponent couldn't find any slots for a box
  // and box deletion from the list if it's a placeholder on server but an empty box on client
  // (not resolved boxes may interfere with aggregation fulfillment and sending ad requests)
  if (!slotType || !slotId) {
    const emptyBox = boxesList[boxId];
    if (emptyBox) {
      delete boxesList[boxId];
    }
    return;
  }

  const boxAggregationProps: BoxAggregationProps = {
    isResolved: slotType !== 'placeholder',
    isSraSlot: checkSraSlot(boxName, slotType, slotId),
    slotId,
    slotType,
    isDynamicSlot: checkDynamicBox(boxName),
  };

  boxesList[boxId] = boxAggregationProps;

  const aggregationComplete = checkAggregationComplete();
  const boxAggregationStore = useCommercialBoxAggregationStore.getState();
  boxAggregationStore.actions.updateBoxAggregationToClientStore({ aggregationComplete, boxesList });
};

export default useCommercialBoxAggregation;
