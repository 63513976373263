import type { HydraStore } from './hydra';

export const getHydraUrl = (hydraStore: HydraStore): string => {
  const hydraState = hydraStore.state.server;
  return hydraState.hydraEndpointURL;
};

export const getHydraFeatureFlag = (hydraStore: HydraStore): boolean => {
  const hydraState = hydraStore.state.server;
  return hydraState.enabled;
};

export const getHydraAuctionTimeout = (
  hydraStore: HydraStore
): { auctionTimeout: number; auctionTimeoutOffset: number } => {
  const { auctionTimeout, auctionTimeoutOffset } = hydraStore.state.server;
  return { auctionTimeout, auctionTimeoutOffset };
};
