import getDirFileName from '../utils/dirPath';

export default async function getArticleCommentCount({
  sectionUuid,
  articleId,
}: {
  sectionUuid: string;
  articleId: string;
}) {
  const url = `https://livecomments.viafoura.co/v4/livecomments/${sectionUuid}/content-containers/comment-count-and-status/by-container-ids`;

  const payload: RequestInit = {
    mode: 'cors' as RequestMode,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': '*/*',
    },
    body: JSON.stringify([articleId]),
  };

  let response;

  try {
    response = await fetch(url, payload);
  } catch (err) {
    const fileDirectory = getDirFileName(import.meta.url);
    console.error(`${fileDirectory} Api Error: `, err);
    throw err;
  }

  const data = await response.json();

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return data;
}
