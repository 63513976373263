import clientWidth from '@/helpers/utils/clientWidth';
import useCountryCode from '@/store/countryCode/countryCode';
import getCountryCode from '@/store/countryCode/selectors';
import useJWClickToPlayGeosConfig from '@/store/jwClickToPlayGeosConfig/jwClickToPlayGeosConfig';
import getJWClickToPlayGeosConfig from '@/store/jwClickToPlayGeosConfig/selector';
import useLightningLoad from '@/store/lightningLoad/lightningLoad';
import { checkPrerollClickToPlayForLightningLoad } from '@/store/lightningLoad/selectors';

const isAutoPlayEnabled = (
  isLeadVideo: boolean,
  cmpReady: boolean,
  isLightningLoadDetected: boolean | null,
  isPrerollClickToPlay: boolean,
  isClickToPlay: boolean
): boolean => {
  const delayUntilConsented = true;
  const canPlayNow = delayUntilConsented ? cmpReady : true;

  if (isLightningLoadDetected && isPrerollClickToPlay) {
    return false;
  }

  return !!(isLeadVideo && canPlayNow && !isClickToPlay) || false;
};

const isAutoPlayReady = (
  cmpReady: boolean,
  lightningLoadDetectionComplete: boolean,
  isLightningLoadDetected: boolean | null,
  initVideoBeforeLightningLoad: boolean
): boolean =>
  initVideoBeforeLightningLoad &&
  cmpReady &&
  lightningLoadDetectionComplete === true &&
  isLightningLoadDetected === false;

const useJWAutoPlay = (
  isLeadVideo: boolean,
  cmpReady: boolean,
  isLightningLoadDetected: boolean | null,
  lightningLoadDetectionComplete: boolean,
  initVideoBeforeLightningLoad: boolean
) => {
  const jwClickToPlayGeoConfigStore = useJWClickToPlayGeosConfig.getState();
  const countryCodeStore = useCountryCode.getState();
  const lightningLoadStore = useLightningLoad();
  const jwClickToPlayGeoConfig = getJWClickToPlayGeosConfig(jwClickToPlayGeoConfigStore);
  const countryCode = getCountryCode(countryCodeStore);
  const isPrerollClickToPlay = checkPrerollClickToPlayForLightningLoad(lightningLoadStore);
  const jwClickToPlayBreakPointConfig =
    jwClickToPlayGeoConfig && Object.hasOwn(jwClickToPlayGeoConfig, clientWidth())
      ? jwClickToPlayGeoConfig[clientWidth()]
      : [];
  const isClickToPlay =
    jwClickToPlayBreakPointConfig.includes(countryCode) || jwClickToPlayBreakPointConfig.includes('ALL');
  return {
    isAutoPlay: isAutoPlayEnabled(isLeadVideo, cmpReady, isLightningLoadDetected, isPrerollClickToPlay, isClickToPlay),
    isAutoPlayReady: isAutoPlayReady(
      cmpReady,
      lightningLoadDetectionComplete,
      isLightningLoadDetected,
      initVideoBeforeLightningLoad
    ),
  };
};

export default useJWAutoPlay;
