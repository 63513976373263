const DEFAULT_LOCALE = 'en-GB';
const DEFAULT_CURRENCY = 'GBP';
const getFormatter = (locale: string, currency: string) =>
  Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });

const getFormattedPrice = (
  price: string,
  currency: string = DEFAULT_CURRENCY,
  locale: string = DEFAULT_LOCALE
): string => (Number.isNaN(Number(price)) ? price : getFormatter(locale, currency).format(Number(price)));

export default getFormattedPrice;
