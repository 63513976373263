import { useEffect, useState } from 'react';
import type { PageSpecifications, SlotConfigProps } from '@/commercial/types';
import type { CommercialBoxChildStyleProps, CommercialBoxContent, CommercialBoxLocation, TemplateFlagKey } from '@/ts';
import { isSingleSizeArray, isMultiSize, isNamedSize } from '@/helpers/commercial/typeValidation';
import isMaxWidthLocation from '@/helpers/commercial/isMaxWidthLocation';
import { getAdConfigForBox, areAllSlotsDisabled } from '@/store/adsConfig/selectors';
import useAdsConfig from '@/store/adsConfig/adsConfig';
import useCountryCode from '@/store/countryCode/countryCode';
import { consentSignalReady, isVendorConsented, isPublisherPurposesConsented } from '@/store/consent/selectors';
import useConsentData from '@/store/consent/consent';
import useVideo from '@/store/video/video';
import clientWidth from '@/helpers/utils/clientWidth';
import useCustomAdFormats from '@/store/customAd/customAdFormats';
import { isFirstPartySkin, isMastheadWebStripAd } from '@/store/customAd/selectors';
import useCommercialFeatureControl from '@/store/commercialFeatureControl/commercialFeatureControl';
import chooseBoxComponent from '@/helpers/commercial/chooseBoxComponent';
import determineCustomTemplateName from '@/helpers/commercial/determineCustomTemplateName';
import checkIfSkyScraperSlot from '@/helpers/utils/checkIfSkyScraperSlot';
import usePageCategory from '@/store/pageCategory/pageCategory';
import useLightningLoad from '@/store/lightningLoad/lightningLoad';
import useConverse from '@/store/converse/converse';
import { checkIsConverseContentAvailable } from '@/store/converse/selectors';
import { checkLightningLoadDetected, checkFeatureDisabledForLightningLoad } from '@/store/lightningLoad/selectors';
import { getCommercialControlFeatures } from '@/store/commercialFeatureControl/selectors';
import useCommercialBoxesConfig from '@/store/commercialBoxesConfig/commercialBoxesConfig';
import getCommercialBoxConfigByBoxName from '@/store/commercialBoxesConfig/selectors';
import getCountryCode from '@/store/countryCode/selectors';
import useOutstreamAuctionTaregting from '@/store/outstreamAuctionTargeting/outstreamAuctionTargeting';
import { hasOutstreamAuctionTargetingComplete, hasOzoneOutstream } from '@/store/outstreamAuctionTargeting/selectors';
import { JWPlayerProps } from '@/components/molecules/JWPlayer/JWPlayer';
import hasSizeMapping from '../helpers/utils/hasSizeMapping';
import {
  isPrimis,
  isSkin,
  isTaboolaFeed,
  isTaboolaMid,
  isTaboolaOutstream,
  isTaboolaRhc,
  isMantis,
  isJWPlayer,
  isConverse,
  isMantisLB,
} from '../helpers/utils/templateFlags';
import getMaxWidth from '../helpers/utils/getCommercialBoxMaxWidth';
import checkTopSlot from '../helpers/utils/checkTopSlot';
import checkLightningLoadDetectionComplete from '../helpers/utils/checkLightningLoadDetectionComplete';
import checkIfGPTOutstreamSlot from '../helpers/utils/checkIfGPTOutstreamSlot';

const getChildStyleProps = (
  adConfigForBox: SlotConfigProps,
  location: CommercialBoxLocation
): CommercialBoxChildStyleProps => {
  let width = 0;
  const { slotId, size } = adConfigForBox;

  const isSizeMapping = hasSizeMapping(adConfigForBox);

  const templateFlags: Record<TemplateFlagKey, boolean> = {
    isTaboolaFeed: isTaboolaFeed(size),
    isTaboolaOutstream: isTaboolaOutstream(size),
    isTaboolaRhc: isTaboolaRhc(size),
    isTaboolaMid: isTaboolaMid(size),
    isPrimis: isPrimis(size),
    isJWPlayer: isJWPlayer(size),
    isSkyScraper: checkIfSkyScraperSlot(slotId),
    isGPTOutstream: checkIfGPTOutstreamSlot(slotId),
    isSkinAd: isSkin(size),
    isStickyTop: checkTopSlot(slotId),
    inArticleSlot: location === 'article-body' && !isMantis(size) && !isMantisLB(size) && !isConverse(size),
    isMantisCarousel: isMantis(size),
    isConverse: isConverse(size),
    isMantisCarouselLB: isMantisLB(size),
  };

  const customTemplateName = determineCustomTemplateName(templateFlags);

  if (!isSizeMapping) {
    if (isSingleSizeArray(size)) {
      [width] = size;
    }
    if (isMultiSize(size) && !isNamedSize(size) && isMaxWidthLocation(location)) {
      width = getMaxWidth(size);
    }
  }

  return {
    $width: width,
    $isSizeMapping: isSizeMapping,
    $customTemplateName: customTemplateName,
  };
};

/**
 * Custom Hook to get the commercial box content
 * @param boxName
 */
const useCommercialBoxContent = (
  boxName: string,
  position: number,
  location: CommercialBoxLocation,
  videoData?: JWPlayerProps
): CommercialBoxContent | null => {
  const [isClient, setIsClient] = useState(false);

  const adsConfigStore = useAdsConfig.getState();

  const countryCodeStore = useCountryCode.getState();

  const videoStore = useVideo.getState();
  const { isVideoOnPage: hasVideoOnPage } = videoStore.state.server;
  const isLeadVideo = videoData ? videoData.isLeadVideo : null;

  const pageCategoryStore = usePageCategory.getState();
  const { isSensitiveArticle, isSportArticle, hasActiveLegalProceedings } = pageCategoryStore.state.server;

  const consentStore = useConsentData();
  const hasGoogleConsent = isVendorConsented('google')(consentStore);
  const isConsentSignalReady: boolean = consentSignalReady(consentStore);
  const hasPublisherPurposesConsented: boolean = isPublisherPurposesConsented()(consentStore);

  const lightningLoadStore = useLightningLoad();
  const isLightningLoadDetected = checkLightningLoadDetected(lightningLoadStore);
  const isLightningLoadDetectionComplete = checkLightningLoadDetectionComplete(isLightningLoadDetected);
  const isConverseDisabledOnLightningLoad = checkFeatureDisabledForLightningLoad(lightningLoadStore, 'converse');

  const customAdStore = useCustomAdFormats();
  const isFirstPartySkinAd = isFirstPartySkin(customAdStore);
  const isWebStripAd = isMastheadWebStripAd(customAdStore);

  const countryCode = getCountryCode(countryCodeStore);
  const outstreamAuctionTargetingStore = useOutstreamAuctionTaregting();
  const hasOzoneBid = hasOzoneOutstream(outstreamAuctionTargetingStore);
  const isOutstreamDetectionComplete = hasOutstreamAuctionTargetingComplete(outstreamAuctionTargetingStore);

  const commercialFeatureControlStore = useCommercialFeatureControl();

  const converseStore = useConverse();
  const isConverseContentAvailable = checkIsConverseContentAvailable(converseStore);
  const { isolateCommercialFeature } = getCommercialControlFeatures(commercialFeatureControlStore);
  const breakpoint = clientWidth();

  const chooseBoxComponentProps: PageSpecifications = {
    isSportArticle,
    isSensitiveArticle,
    hasActiveLegalProceedings,
    isLightningLoadDetectionComplete,
    isOutstreamDetectionComplete,
    isLightningLoadDetected,
    isConsentSignalReady,
    hasGoogleConsent,
    hasVideoOnPage,
    isLeadVideo,
    hasOzoneBid,
    breakpoint,
    isClient,
    isSkinAd: isFirstPartySkinAd,
    isMastheadWebstripAd: isWebStripAd,
    countryCode,
    isConverseContentAvailable,
    hasPublisherPurposesConsented,
    isConverseDisabledOnLightningLoad,
  };

  const commercialBoxesConfigStore = useCommercialBoxesConfig.getState();
  const boxConfigData = getCommercialBoxConfigByBoxName(boxName)(commercialBoxesConfigStore);

  const areAllBoxSlotsDisabled = areAllSlotsDisabled(boxConfigData)(adsConfigStore);

  useEffect(() => {
    setIsClient(true);
  }, [breakpoint]);

  if (areAllBoxSlotsDisabled) {
    return null;
  }

  const boxConfig = chooseBoxComponent(boxName, chooseBoxComponentProps, isolateCommercialFeature);
  if (!boxConfig) {
    return null;
  }
  // Return placeholder data if slot type is 'placeholder'
  if (
    boxConfig.slotType === 'placeholder' ||
    boxConfig.slotType === 'placeholderWithAuction' ||
    boxConfig.slotType === 'placeholderCollapse'
  ) {
    const { slotType: childName, size } = boxConfig;
    return {
      childName,
      childProps: { slotId: boxConfig.slotId },
      commercialBoxChildStyleProps: { $width: 0, $isSizeMapping: false, $customTemplateName: '' },
      size,
    };
  }

  const { slotType, slotId } = boxConfig;
  const adConfigForBox = getAdConfigForBox(slotId, slotType, position)(adsConfigStore);
  if (!adConfigForBox) {
    return null;
  }

  const commercialBoxChildStyleProps = getChildStyleProps(adConfigForBox, location);

  if (boxConfig.slotType === 'jwplayer') {
    const { slotType: childName } = boxConfig;
    return {
      childName,
      childProps: { ...adConfigForBox, videoData },
      commercialBoxChildStyleProps,
    };
  }

  return {
    childName: slotType,
    childProps: adConfigForBox,
    commercialBoxChildStyleProps,
  };
};

export default useCommercialBoxContent;
