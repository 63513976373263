'use client';

// @ts-ignore-line typescript is not supported
import ReactJWPlayer from 'react-jw-player';
import { useEffect, useState, useRef, useMemo } from 'react';
import type { GenericVideoProps, JWPlayerInstance } from '@/ts';
import { JW_BASE_URL, VIDEO_WRAPPER_DIV_CLASS } from '@/constants/video';
import useVideo from '@/store/video/video';
import useCommercialFeatureControl from '@/store/commercialFeatureControl/commercialFeatureControl';
import useConsentDataService from '@/store/consent/consent';
import useMantis from '@/store/mantis/mantis';
import usePage from '@/store/page/page';
import useLightningLoad from '@/store/lightningLoad/lightningLoad';
import { checkLightningLoadDetected } from '@/store/lightningLoad/selectors';
import { mantisDataForVideo, mantisIdForVendor } from '@/store/mantis/selectors';
import { consentSignalReady } from '@/store/consent/selectors';
import clientWidth from '@/helpers/utils/clientWidth';
import { getVideoAdUnitPath } from '@/store/adsConfig/selectors';
import { floatAndParkDataForVideo } from '@/store/video/selectors';
import useAdsConfig from '@/store/adsConfig/adsConfig';
import useJWPlayerTracking from '@/hooks/useJWPlayerTracking';
import yieldToMain from '@/helpers/utils/yieldToMain';
import checkLightningLoadDetectionComplete from '@/helpers/utils/checkLightningLoadDetectionComplete';
import { getStoryMeta } from '@/store/page/selectors';
import { getCommercialControlFeatures, getFeatureEnabled } from '@/store/commercialFeatureControl/selectors';
import useJWAutoPlay from '@/hooks/useJWAutoPlay';
import getFloatAndParkConfig from './JWFloatAndPark';
import { getVideoAdsConfig, adInit } from './JWAds';
import ErrorBoundaryFallback from '../ErrorBoundaryFallback/ErrorBoundaryFallback';
import styles from './styles/JWPlayer.module.scss';

interface CustomPropsObject {
  [key: string]: any;
}

export interface JWPlayerProps {
  videoId: string;
  playerId: string;
  videoHeadline: string;
  apexDomain: string;
  isLeadVideo: boolean;
  aspectRatio?: '1:1' | '16:9' | 'inherit';
  showHeadline?: boolean;
  dataTestId?: string;
  childIndex?: number;
  thumbnailURL: string;
  datePublished: string;
  contentVertical: string;
  uploadedBy: string;
  clearance: string;
  brandSafety: string;
  initVideoBeforeLightningLoad: boolean;
}

const isClientReady = (
  isDesktop: boolean | null,
  cmpReady: boolean,
  mantisId: string | boolean,
  lightningLoadDetectionComplete: boolean,
  initVideoBeforeLightningLoad: boolean
): boolean =>
  isDesktop !== null &&
  cmpReady &&
  mantisId !== null &&
  (lightningLoadDetectionComplete === true || initVideoBeforeLightningLoad);

const JWPlayer: React.FC<JWPlayerProps> = ({
  aspectRatio,
  showHeadline,
  videoHeadline,
  apexDomain,
  videoId,
  playerId,
  isLeadVideo,
  childIndex,
  thumbnailURL,
  datePublished,
  contentVertical,
  uploadedBy,
  clearance,
  brandSafety,
  initVideoBeforeLightningLoad,
}) => {
  const playerRef = useRef<JWPlayerInstance | null>(null);
  const isAutoPlayAndMuted = useRef<boolean>(false);
  const customPropsConfig = useRef<CustomPropsObject>({});
  const videoTitle = useRef<string>(videoHeadline);
  const videoDuration = useRef<number>(0);
  const videoStore = useVideo();
  const consentStore = useConsentDataService();
  const mantisStore = useMantis();
  const pageStore = usePage();
  const adsConfigStore = useAdsConfig();
  const lightningLoadStore = useLightningLoad();
  const commercialFeatureControlStore = useCommercialFeatureControl();

  const { isolateCommercialFeature } = getCommercialControlFeatures(commercialFeatureControlStore);
  const isPrerollEnabled = getFeatureEnabled('preroll')(commercialFeatureControlStore);
  const mantisData = mantisDataForVideo(mantisStore);
  const mantisId = mantisIdForVendor('google')(mantisStore, consentStore);
  const storymeta = getStoryMeta(pageStore);
  const cmpReady: boolean = consentSignalReady(consentStore);
  const isClientDesktop = clientWidth() !== 'small';
  const videoAdUnitPath = getVideoAdUnitPath(adsConfigStore);
  const floatAndParkData = floatAndParkDataForVideo(videoStore);
  const isLightningLoadDetected = checkLightningLoadDetected(lightningLoadStore);

  // Set true when isolateCommercialFeature query param passed; otherwise, the lightningLoadDetectionComplete is false and video will not play
  const lightningLoadDetectionComplete =
    checkLightningLoadDetectionComplete(isLightningLoadDetected) ||
    !(isolateCommercialFeature === '' || isolateCommercialFeature === 'gpt');

  const [isPlayer, setPlayer] = useState(false);
  const [isPoster, setIsPoster] = useState(true);

  const playerHtmlId: string = `video-${childIndex}-${playerId}-${videoId}`;
  const dataTestId: string = isLeadVideo ? 'leadvideo' : `videoembed-${videoId}`;

  const { isAutoPlay, isAutoPlayReady } = useJWAutoPlay(
    isLeadVideo,
    cmpReady,
    isLightningLoadDetected,
    lightningLoadDetectionComplete,
    initVideoBeforeLightningLoad
  );
  const playerPosition: string = isLeadVideo ? 'lead' : 'embed';
  const interaction: string = isAutoPlay ? 'autoplay' : playerPosition;
  const genericVideoProps: GenericVideoProps = useMemo(
    () => ({
      playerId,
      videoId,
      videoHeadline,
      apexDomain,
      mantisData,
      mantisId,
      interaction,
      playerHtmlId,
      videoAdUnitPath,
      brandSafety,
      storymeta,
      isAutoPlay,
    }),
    [
      playerId,
      videoId,
      videoHeadline,
      apexDomain,
      mantisData,
      mantisId,
      interaction,
      playerHtmlId,
      videoAdUnitPath,
      brandSafety,
      storymeta,
      isAutoPlay,
    ]
  );

  const onReady = () => {
    if (window.jwplayer !== undefined) {
      setIsPoster(false);
      if (!playerRef.current) playerRef.current = window.jwplayer(playerHtmlId);
      if (playerRef.current !== null) videoDuration.current = playerRef.current.getDuration();
      if (isPrerollEnabled) {
        adInit(window.jwplayer(playerHtmlId), genericVideoProps);
      }
    }
  };

  const startAutoplay = () => {
    const player = window.jwplayer(playerHtmlId);
    player.setConfig({ autostart: true });
  };

  useEffect(() => {
    const initializePlayer = async () => {
      if (!customPropsConfig.current.advertising && isPrerollEnabled) {
        customPropsConfig.current.advertising = await getVideoAdsConfig(genericVideoProps);
      }
      customPropsConfig.current.floating = getFloatAndParkConfig(
        isLeadVideo,
        isClientDesktop,
        isAutoPlay,
        floatAndParkData
      );
      await yieldToMain();
      isAutoPlayAndMuted.current = isAutoPlay;
      setPlayer(true);
    };
    if (
      isClientReady(isClientDesktop, cmpReady, mantisId, lightningLoadDetectionComplete, initVideoBeforeLightningLoad)
    )
      initializePlayer();
    if (isAutoPlayReady) startAutoplay();
  }, [
    cmpReady,
    isAutoPlayAndMuted,
    isClientDesktop,
    mantisId,
    isPrerollEnabled,
    isLeadVideo,
    isAutoPlay,
    floatAndParkData,
    genericVideoProps,
    isLightningLoadDetected,
    lightningLoadDetectionComplete,
    initVideoBeforeLightningLoad,
  ]);

  useJWPlayerTracking(
    playerRef.current,
    playerId,
    videoId,
    videoHeadline,
    interaction,
    playerPosition,
    datePublished,
    contentVertical,
    uploadedBy,
    clearance
  );

  return (
    <ErrorBoundaryFallback>
      <section
        className={styles['jw-player-container']}
        role="complementary"
        aria-label={`video: ${videoHeadline}`}
        data-testid={dataTestId}
      >
        {isPoster && (
          <div className={`poster ${VIDEO_WRAPPER_DIV_CLASS} ${playerHtmlId}`} data-testid="poster">
            <img className={styles['poster-image']} src={thumbnailURL} alt="videoHeadline" />
          </div>
        )}
        {isPlayer && (
          <div style={{ display: isPoster ? 'none' : 'block' }}>
            <ReactJWPlayer
              customProps={customPropsConfig.current}
              isAutoPlay={initVideoBeforeLightningLoad ? false : isAutoPlayAndMuted.current}
              isMuted={isAutoPlayAndMuted.current}
              aspectRatio={aspectRatio}
              file={`${JW_BASE_URL}/manifests/${videoId}.m3u8`}
              playerScript={`${JW_BASE_URL}/libraries/${playerId}.js`}
              playerId={playerHtmlId}
              playlist={`${JW_BASE_URL}/v2/media/${videoId}`}
              onReady={onReady}
              className={VIDEO_WRAPPER_DIV_CLASS}
            />
          </div>
        )}
        {showHeadline && <span className={styles.headline}>{videoTitle.current}</span>}
      </section>
    </ErrorBoundaryFallback>
  );
};

JWPlayer.defaultProps = {
  showHeadline: true,
};

export default JWPlayer;
