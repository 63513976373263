'use client';

import Script from 'next/script';
import React, { useEffect } from 'react';
import type { MantisConfigProps } from '@/commercial/types';
import usePage from '@/store/page/page';
import { getSubType } from '@/store/page/selectors';
import useLightningLoad from '@/store/lightningLoad/lightningLoad';
import { checkFeatureDisabledForLightningLoad, checkLightningLoadDetected } from '@/store/lightningLoad/selectors';

export const transformURL = (url: string) => {
  if (url) {
    const urlWithoutProtocol = url.split('//')[1];
    const firstDotIndex = urlWithoutProtocol.indexOf('.');
    const transformedUrl = `www${urlWithoutProtocol.slice(firstDotIndex)}`;
    return transformedUrl;
  }
  return '';
};

const isAdsDisabledForSubType = (subType: string) => {
  const adsRestrictedSubTypes = ['affiliate'];
  return adsRestrictedSubTypes.includes(subType);
};

const MantisCarousel: React.FC<MantisConfigProps> = ({ slotId, loaderScriptUrl, mantisCarouselKeyValues }) => {
  const pageData = usePage();
  const subType = getSubType(pageData);
  const { seoUrl } = pageData.state.server;
  const srcUrl = transformURL(seoUrl);

  const lightningLoadStore = useLightningLoad.getState();
  const lightningLoadSignal = checkLightningLoadDetected(lightningLoadStore);
  const isMantisAdDisabled =
    isAdsDisabledForSubType(subType) || checkFeatureDisabledForLightningLoad(lightningLoadStore, 'mantisAd');

  useEffect(() => {
    if (typeof lightningLoadSignal === 'boolean' && !isMantisAdDisabled) {
      window?.mantis?.carousels?.[slotId]?.displayAdSlots();
    }
  }, [lightningLoadSignal, isMantisAdDisabled, slotId]);

  const content = (
    <>
      {React.createElement('mantis-ui-widget', {
        id: slotId,
        'data-config': JSON.stringify({
          displayAdSlots: 'false',
          url: srcUrl,
          subType,
          ...mantisCarouselKeyValues,
        }),
        'data-testid': 'mantis-carousel',
      })}
      <Script src={loaderScriptUrl} strategy="afterInteractive" />
    </>
  );

  return <>{content}</>;
};

export default MantisCarousel;
