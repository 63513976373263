interface SocialPLatformsConstants {
  FACEBOOK: string;
  X: string;
  INSTAGRAM: string;
  TIKTOK: string;
  YOUTUBE: string;
  SNAPCHAT: string;
  WHATSAPP: string;
  NEXTDOOR: string;
  TWITTER: string;
}

const SOCIAL_PLATFORMS: Readonly<SocialPLatformsConstants> = {
  FACEBOOK: 'Facebook',
  X: 'X',
  INSTAGRAM: 'Instagram',
  TIKTOK: 'TikTok',
  YOUTUBE: 'Youtube',
  SNAPCHAT: 'Snapchat',
  WHATSAPP: 'WhatsApp',
  NEXTDOOR: 'NextDoor',
  TWITTER: 'Twitter',
};

export default SOCIAL_PLATFORMS;
