import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { JWClickToPlayGeos, JWClickToPlayGeosConfigState } from '@/commercial/types';

export interface JWClickToPlayGeosConfigStore {
  state: JWClickToPlayGeosConfigState;
  actions: {
    addClickToPlayGeoConfig: (jwClickToPlayGeos: JWClickToPlayGeos) => void;
  };
}

const initialJWClickToPlayGeosConfigState: JWClickToPlayGeosConfigState = {
  client: {
    jwClickToPlayGeos: {
      small: [],
      medium: [],
      large: [],
      xlarge: [],
    },
  },
};

const useJWClickToPlayGeosConfig = create<JWClickToPlayGeosConfigStore>()(
  devtools(
    (set) => ({
      state: { ...initialJWClickToPlayGeosConfigState },
      actions: {
        addClickToPlayGeoConfig: (jwClickToPlayGeos: JWClickToPlayGeos) => {
          set(() => ({
            state: {
              client: {
                jwClickToPlayGeos,
              },
            },
          }));
        },
      },
    }),
    {
      name: 'jwClickToPlayGeosConfig',
    }
  )
);

export default useJWClickToPlayGeosConfig;
