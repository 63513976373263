export const isTaboolaFeed = (item: unknown): boolean => item === 'Feed';
export const isTaboolaOutstream = (item: unknown): boolean => item === 'Outstream';
export const isTaboolaRhc = (item: unknown): boolean => item === 'Rhc' || item === 'Rhc4x1';
export const isTaboolaMid = (item: unknown): boolean => item === 'Mid';
export const isPrimis = (item: unknown): boolean => item === 'Primis';
export const isJWPlayer = (item: unknown): boolean => item === 'JWPlayerSize';
export const isSkin = (item: unknown): boolean => item === 'SkinAd';
export const isMantis = (item: unknown): boolean => item === 'MantisCarousel';
export const isMantisLB = (item: unknown): boolean => item === 'MantisCarouselLB';
export const isConverse = (item: unknown): boolean => item === 'Converse';
