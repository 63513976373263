import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { initialOutstreamAuctionTargetingState } from '@/store/assets/initialStates';
import { OutstreamAuctionTargetingState, GPTTargetingMap } from '@/commercial/types';

export interface OutstreamAuctionTargetingStore {
  state: OutstreamAuctionTargetingState;
  actions: {
    addOutstreamAuctionTargetingToStore: (state: Record<string, GPTTargetingMap>) => void;
    setOutstreamAuctionTargetingComplete: (state: boolean) => void;
  };
}

const useOutstreamAuctionTargeting = create<OutstreamAuctionTargetingStore>()(
  devtools(
    (set) => ({
      state: { ...initialOutstreamAuctionTargetingState },
      actions: {
        addOutstreamAuctionTargetingToStore: (targetingData: Record<string, GPTTargetingMap>) => {
          set((currentState) => ({
            state: {
              ...currentState.state,
              client: {
                ...currentState.state.client,
                outstreamTargetingData: targetingData,
              },
            },
          }));
        },
        setOutstreamAuctionTargetingComplete: (auctionComplete: boolean) => {
          set((currentState) => ({
            state: {
              ...currentState.state,
              client: {
                ...currentState.state.client,
                isOutstreamAuctionTargetingComplete: auctionComplete,
              },
            },
          }));
        },
      },
    }),
    {
      name: 'outstreamAuctionTargeting',
    }
  )
);

export default useOutstreamAuctionTargeting;
