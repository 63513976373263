import { partial } from 'lodash';
import type { JWPlayerInstance, PlaylistItem, GenericVideoProps } from '@/ts';
import generateAdRequestURL from '@/commercial/videoAds';
import { VIDEO_WRAPPER_DIV_CLASS } from '@/constants/video';
import { getHydra } from '@/commercial/helpers/hydra';
import { GPTTargetingMap, VideoAdRequestURLProps, VideoSlotConfig } from '@/commercial/types';

let videoIndex = 0;
const AUCTION_TIMEOUT = 2000;

const auctionCache = new Map<string, Promise<GPTTargetingMap>>();

const hashVideoSlotConfig = (config: VideoSlotConfig): string => JSON.stringify(config);

const requestVideoAuction = async (videoSlotConfig: VideoSlotConfig): Promise<GPTTargetingMap> => {
  const configHash = hashVideoSlotConfig(videoSlotConfig);
  if (auctionCache.has(configHash)) {
    return auctionCache.get(configHash)!;
  }
  const auctionPromise = new Promise<GPTTargetingMap>((resolve) => {
    const hydra = getHydra();
    // Promise resolves even if Hydra takes too long
    const auctionTimeout = setTimeout(() => {
      resolve({});
    }, AUCTION_TIMEOUT + 100);
    hydra.push({
      cmd: 'trigger auction',
      slots: [videoSlotConfig],
      timeout: AUCTION_TIMEOUT,
      callback: (auctionTargetingData?: Record<string, GPTTargetingMap>) => {
        if (auctionTargetingData?.video) {
          clearTimeout(auctionTimeout);
          resolve(auctionTargetingData.video);
        }
      },
    });
  });
  auctionCache.set(configHash, auctionPromise);
  return auctionPromise;
};

export const playlistCallBack = async (
  { player, props }: { player: JWPlayerInstance; props: GenericVideoProps },
  item: PlaylistItem
) => {
  const videoId = item.mediaid;
  const videoHeadline = item.title; // using headline and title interchangeably
  const currentSize: googletag.SingleSizeArray = [player.getWidth(), player.getHeight()];
  const {
    playerId,
    apexDomain,
    mantisData,
    mantisId,
    interaction,
    videoAdUnitPath,
    brandSafety,
    storymeta,
    isAutoPlay,
  } = props;
  videoIndex += 1;
  const videoSlotConfigForAuction: VideoSlotConfig = {
    playerId,
    videoId,
    videoIndex,
    currentSize,
    mantisData,
    mantisId,
    interaction,
    videoAdUnitPath,
    brandSafety,
    isAutoPlay,
    slotId: 'video',
  };
  const videoAuctionTargeting = await requestVideoAuction(videoSlotConfigForAuction);
  const videoProps: VideoAdRequestURLProps = {
    ...videoSlotConfigForAuction,
    videoHeadline,
    apexDomain,
    storymeta,
    videoAuctionTargeting,
  };
  const updatedPlaylistItem = {
    ...item,
    adschedule: [
      {
        tag: generateAdRequestURL(videoProps).href,
        offset: 'pre',
      },
    ],
  };
  return updatedPlaylistItem;
};

export function adInit(player: JWPlayerInstance, props: GenericVideoProps) {
  const callback = partial(playlistCallBack, { player, props });
  player.setPlaylistItemCallback(callback); // ads for 2nd and subsequent videos
}
const getInitialPlayerSize = (playerHtmlId: string): [number, number] => {
  // use wrapper div because player/placeholder div(s) do not have height yet
  const allWrapperElems = [...document.querySelectorAll(`.${VIDEO_WRAPPER_DIV_CLASS}`)];
  const wrapperElem = allWrapperElems.find(
    (el) => el.querySelector(`#${playerHtmlId}`) || el.classList.contains(playerHtmlId)
  );
  const { width, height } = wrapperElem?.getBoundingClientRect() ?? { width: 0, height: 0 };
  return [Math.floor(width), Math.floor(height)];
};

export const getVideoAdsConfig = async (props: GenericVideoProps) => {
  const {
    playerId,
    videoId,
    videoHeadline,
    apexDomain,
    mantisData,
    mantisId,
    interaction,
    playerHtmlId,
    videoAdUnitPath,
    brandSafety,
    storymeta,
    isAutoPlay,
  } = props;
  videoIndex = 1;
  const currentSize = getInitialPlayerSize(playerHtmlId);
  const videoSlotConfigForAuction: VideoSlotConfig = {
    playerId,
    videoId,
    videoIndex,
    currentSize,
    mantisData,
    mantisId,
    interaction,
    videoAdUnitPath,
    brandSafety,
    isAutoPlay,
    slotId: 'video',
  };
  const videoAuctionTargeting = await requestVideoAuction(videoSlotConfigForAuction);
  const videoProps: VideoAdRequestURLProps = {
    ...videoSlotConfigForAuction,
    videoHeadline,
    apexDomain,
    storymeta,
    videoAuctionTargeting,
  };

  const adConfig = {
    client: 'googima',
    schedule: [
      {
        tag: generateAdRequestURL(videoProps).href, // ads for first video
        offset: 'pre',
      },
    ],
  };
  return adConfig;
};
