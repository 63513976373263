import SOCIAL_PLATFORMS from '@/constants/social-platforms';
import { SocialPlatformType } from '@/ts/types/social-platforms';
import { SocialPlatformData } from '@/ts/interfaces/socialPlatforms';

const { FACEBOOK, X, INSTAGRAM, TIKTOK, YOUTUBE, SNAPCHAT, WHATSAPP } = SOCIAL_PLATFORMS;

export const PLATFORM_ORDER: Record<SocialPlatformType, number> = {
  [FACEBOOK]: 1,
  [X]: 2,
  [TIKTOK]: 3,
  [INSTAGRAM]: 4,
  [YOUTUBE]: 5,
  [SNAPCHAT]: 6,
  [WHATSAPP]: 7,
};

export const sortPlatforms = (a: SocialPlatformData, b: SocialPlatformData): number =>
  (PLATFORM_ORDER[a.name as SocialPlatformType] || Number.MAX_VALUE) -
  (PLATFORM_ORDER[b.name as SocialPlatformType] || Number.MAX_VALUE);
