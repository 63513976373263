import { ConverseStore } from './converse';

export const checkIsConverseContentAvailable = (converseStore: ConverseStore) => {
  const htmlTemplate = converseStore?.state?.client?.htmlTemplate;
  return !!htmlTemplate && htmlTemplate !== '';
};

export const getHtmlTemplate = (converseStore: ConverseStore) => {
  const htmlTemplate = converseStore?.state?.client?.htmlTemplate;
  return htmlTemplate;
};
