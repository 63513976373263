/**
 * This module provides selectors that utilize both adsConfig and gptSlots stores
 */
import { AdsConfigStore } from '@/store/adsConfig/adsConfig';
import { getGPTConfigById } from '@/store/adsConfig/selectors';
import { GptSlotsStore } from '@/store/gptSlots/gptSlots';

/**
 * Exclude native slots, IBTO (Impression-Based-Take-Over) should be true when the slot count is nine or fewer, and false when it exceeds nine
 * @param gptSlotsStore
 * @param adsConfigStore
 * @returns
 */
export const isIBTOSupported =
  (adsConfigStore: AdsConfigStore) =>
  (gptSlotsStore: GptSlotsStore): boolean => {
    const numberOfRequiredSlotsForIBTO = 9;
    const gptSlots = Object.entries(gptSlotsStore.state.client);

    const numberOfIBTOSlots: number = gptSlots.filter(([slotId]) => {
      const slotConfig = getGPTConfigById(slotId)(adsConfigStore);
      return Boolean(slotConfig?.isEligibleForIBTO);
    }).length;
    return numberOfIBTOSlots <= numberOfRequiredSlotsForIBTO;
  };

export default isIBTOSupported;
