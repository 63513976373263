'use client';

import CommercialTopSlotCounterStyle from '@/components/atoms/CommercialTopSlotCounter/styles/CommercialTopSlotCounterStyle';
import { useTopSlotSticky } from '@/hooks/useTopSlotSticky';

interface TopSlotProps {
  topslotRef: React.RefObject<HTMLDivElement>;
  showCounter: boolean;
}

/**
 *  CommercialTopSlotStickyCounter is required in topslot and skinTop as well because this listens to the shouldUnstickTopSlot
 *  value of CustomAdFormats Store which we are modifying in CustomAdService in case Skin is detected.
 *  showCounter is marked false, because we literally don't want to show the counter after skin gets loaded.
 */
const CommercialTopSlotStickyCounter: React.FC<TopSlotProps> = ({ topslotRef, showCounter }) => {
  const { hasTimeoutStarted, count } = useTopSlotSticky({
    topslotRef,
  });
  return (
    <>
      {hasTimeoutStarted && count > 0 && showCounter && (
        <CommercialTopSlotCounterStyle data-testid="commercial-top-slot-counter" id="commercial-top-slot-counter">
          {count}
        </CommercialTopSlotCounterStyle>
      )}
    </>
  );
};

export default CommercialTopSlotStickyCounter;
