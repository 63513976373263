import type { LightningLoadStore } from './lightningLoad';

type LightningLoadDisabledFeatures = 'mantisAd' | 'sovrn' | 'primis' | 'converse';

export const checkFeatureDisabledForLightningLoad = (
  lightningLoadStore: LightningLoadStore,
  featureName: LightningLoadDisabledFeatures
) => {
  const { customizations } = lightningLoadStore.state.client?.lightningLoadDetectionData[0] || {};

  const { disabled } = customizations?.[featureName] || { disabled: false };
  return disabled;
};

export const checkPrerollClickToPlayForLightningLoad = (lightningLoadStore: LightningLoadStore) => {
  const { customizations } = lightningLoadStore.state.client?.lightningLoadDetectionData[0] || {};
  const { clickToPlay } = customizations?.preroll || {};
  return clickToPlay;
};

export const checkLightningLoadDetected = (lightningLoadStore: LightningLoadStore) => {
  const { isLightningLoadDetected } = lightningLoadStore.state.client || {};
  return isLightningLoadDetected;
};
