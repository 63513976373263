import type { GPTConfigProps, SlotConfigProps, GPTTargetingMap } from '@/commercial/types';
import type { BoxConfigData, HydraSlotConfig, SlotType } from '@/ts';
import { isDefined } from '@/helpers/commercial/typeValidation';
import { AdsConfigStore } from './adsConfig';

const defaultGPTSlotProps: GPTConfigProps = {
  displayAdUnitPath: '',
  enabled: false,
  size: [],
  slotId: '',
  targeting: {},
  useAdvertLabel: false,
  useCLSPlaceholder: false,
};

const repeatSlotId = 'div-gpt-ad-mid-article-slot-repeat';
const repeatSlotIdTaboolaMobile = 'div-taboola-ad-mobile-mid-article-fallback';
const repeatSlotIdTaboolaDesktop = 'div-taboola-ad-desktop-mid-article-fallback';
const repeatCommentSlotIdTaboolaMobile = 'div-taboola-ad-mobile-comment-fallback';
const repeatCommentSlotIdTaboolaDesktop = 'div-taboola-ad-desktop-comment-fallback';

const standardPlacementIndexes = [6, 7, 9];

const getTargetingIndex = (targeting: GPTTargetingMap | undefined, position: number): number => {
  // Assumption is that repeat slot index start with the base index ex:2201
  // repeated target index will be baseIndex + (position - 1) i.e, 2201, 2202, ...
  const targetIndex = targeting?.index as string;
  const index = targetIndex ? parseInt(targetIndex, 10) : NaN;
  return Number.isNaN(index) ? position : index + (position - 1);
};

const getBoxPositionFromSlotId = (slotId: string) => {
  const getSlotIdIndex = slotId.split('-');
  return parseInt(getSlotIdIndex[getSlotIdIndex.length - 1], 10);
};

const repeatSlotIdAndTargeting = (slotId: string, position: number, targeting?: GPTTargetingMap) => ({
  slotId: Number.isNaN(getBoxPositionFromSlotId(slotId)) ? `${slotId}-${position}` : slotId,
  targeting: standardPlacementIndexes.includes(position)
    ? { ...targeting, index: `${getTargetingIndex(targeting, position)}`, mantis_placement: 'standard' }
    : { ...targeting, index: `${getTargetingIndex(targeting, position)}` },
});

const repeatSlotIdAndContainerIdMobile = (id: string, type: string, position: number) => ({
  slotId: `${id}-${position}`,
  keyValues: {
    publisher: 'tmg-network',
    mode: 'thumbnails-m',
    container: `taboola-mobile-${type}-thumbnails-fallback-${position}`,
    placement: 'Mid Article Thumbnails',
    target_type: 'mix',
  },
});

const repeatSlotIdAndContainerIdDesktop = (id: string, type: string, position: number) => ({
  slotId: `${id}-${position}`,
  keyValues: {
    publisher: 'tmg-network',
    mode: 'thumbnails-mm',
    container: `taboola-${type}-thumbnails-fallback-${position}`,
    placement: 'Mid Article Thumbnails',
    target_type: 'mix',
  },
});

const mapAdsConfig = (adsConfigStore: AdsConfigStore) => {
  const { displayAdUnitPath } = adsConfigStore.state.server;
  return {
    gpt: Object.values(adsConfigStore.state.server.gpt).map((gpt) => ({ ...gpt, displayAdUnitPath })),
    taboola: [...adsConfigStore.state.server.taboola],
    mantisCarousel: [...adsConfigStore.state.server.mantisCarousel],
    primis: [...adsConfigStore.state.server.primis],
    jwplayer: adsConfigStore.state.server.jwplayer,
    skinAd: [...adsConfigStore.state.server.skinAd],
    mastheadWebstripAd: [...adsConfigStore.state.server.mastheadWebstripAd],
    converse: [...adsConfigStore.state.server.converse],
  };
};

const getAdConfigBySlotIdAndType =
  (
    slotId: string,
    slotType: Exclude<SlotType, 'placeholder' | 'placeholderWithAuction' | 'placeholderCollapse' | 'empty'>
  ) =>
  (adsConfigStore: AdsConfigStore): SlotConfigProps | undefined => {
    const adsConfig = mapAdsConfig(adsConfigStore);
    const slotsForType: SlotConfigProps[] = adsConfig[slotType] || [];
    return slotsForType.find(
      (slotDef) =>
        (slotId === slotDef.slotId || (slotDef.repeat && slotId.includes(slotDef.slotId))) && slotDef.enabled === true
    );
  };

export const getGPTTargeting =
  (slotId: string) =>
  (adsConfigStore: AdsConfigStore): GPTTargetingMap => {
    const { targeting } = adsConfigStore.state.server.gpt[slotId];
    return targeting;
  };

export const getGPTConfigById =
  (slotId: string) =>
  (adsConfigStore: AdsConfigStore): GPTConfigProps | null => {
    const isRepeatIdMatched = slotId.includes(repeatSlotId);
    const refSlotId = isRepeatIdMatched ? repeatSlotId : slotId;
    const adConfig = getAdConfigBySlotIdAndType(refSlotId, 'gpt')(adsConfigStore);
    const slotConfig =
      adConfig && (adConfig.repeat || isRepeatIdMatched)
        ? {
            ...adConfig,
            ...repeatSlotIdAndTargeting(
              isRepeatIdMatched ? repeatSlotId : adConfig.slotId,
              getBoxPositionFromSlotId(slotId),
              getGPTTargeting(isRepeatIdMatched ? repeatSlotId : adConfig.slotId)(adsConfigStore)
            ),
          }
        : adConfig;
    if (!slotConfig) return null;
    const updatedSlotConfig: GPTConfigProps = {
      ...defaultGPTSlotProps,
      ...(slotConfig as GPTConfigProps),
    };
    return updatedSlotConfig;
  };

export const getVideoAdUnitPath = (adsConfigStore: AdsConfigStore): string => {
  const { videoAdUnitPath } = adsConfigStore.state.server;
  return videoAdUnitPath;
};

const repeatGPTSlot = (props: SlotConfigProps, position?: number) =>
  position && typeof position === 'number' && (props?.repeat || props?.slotId === repeatSlotId)
    ? { ...props, ...repeatSlotIdAndTargeting(props?.slotId, position, (props as GPTConfigProps)?.targeting) }
    : props;

const repeatTaboolaSlot = (props: SlotConfigProps, position: number) => {
  if (props?.slotId === repeatSlotIdTaboolaMobile) {
    return { ...props, ...repeatSlotIdAndContainerIdMobile(repeatSlotIdTaboolaMobile, 'mid-article', position) };
  }
  if (props?.slotId === repeatCommentSlotIdTaboolaMobile) {
    return { ...props, ...repeatSlotIdAndContainerIdMobile(repeatCommentSlotIdTaboolaMobile, 'comment', position) };
  }
  if (props?.slotId === repeatSlotIdTaboolaDesktop) {
    return { ...props, ...repeatSlotIdAndContainerIdDesktop(repeatSlotIdTaboolaDesktop, 'mid-article', position) };
  }
  if (props?.slotId === repeatCommentSlotIdTaboolaDesktop) {
    return { ...props, ...repeatSlotIdAndContainerIdDesktop(repeatCommentSlotIdTaboolaDesktop, 'comment', position) };
  }
  return props;
};

const repeatSlotStrategy = {
  gpt: repeatGPTSlot,
  taboola: repeatTaboolaSlot,
};

export const getAdConfigForBox =
  (
    slotId: string,
    slotType: Exclude<SlotType, 'placeholder' | 'placeholderWithAuction' | 'placeholderCollapse' | 'empty'>,
    position: number
  ) =>
  (adsConfigStore: AdsConfigStore): SlotConfigProps | undefined => {
    let props = getAdConfigBySlotIdAndType(slotId, slotType)(adsConfigStore);
    // Modify for any kind of repeat slots
    if (props?.repeat && (slotType === 'gpt' || slotType === 'taboola')) {
      props = repeatSlotStrategy[slotType](props, position);
    }
    return props;
  };

export const getHydraSlotConfig =
  (slotIds: string[]) =>
  (adsConfigStore: AdsConfigStore): HydraSlotConfig[] => {
    const slots: (HydraSlotConfig | null)[] = slotIds.map((slotId) => {
      const slotConfig = getGPTConfigById(slotId)(adsConfigStore);
      if (slotConfig) {
        return {
          adUnitPath: slotConfig.displayAdUnitPath,
          slotId,
          targeting: slotConfig.targeting,
        };
      }
      return null;
    });
    return slots.filter(isDefined);
  };

const getAdConfigBySlotId =
  (slotId: string) =>
  (adsConfigStore: AdsConfigStore): SlotConfigProps | undefined => {
    const adsConfig = mapAdsConfig(adsConfigStore);

    return Object.values(adsConfig)
      .flat()
      .find((slotDef) => slotDef.slotId === slotId);
  };

/**
 * areAllSlotsDisabled - tells us if ALL of the possible slots that a box could resolve to
 *   are disabled. We need this to prevent the placeholder for that box from being injected.
 */
export const areAllSlotsDisabled = (boxConfigData: BoxConfigData[]) => (adsConfigStore: AdsConfigStore) => {
  // Ignore 'placeholder' slots
  const nonPlaceholderConfigs = boxConfigData.filter((config) => config.slotType !== 'placeholder');
  if (nonPlaceholderConfigs.length === 0) {
    return true;
  }

  return nonPlaceholderConfigs.every((config) => {
    const { slotId } = config;

    const slotConfig = getAdConfigBySlotId(slotId)(adsConfigStore);
    return !slotConfig?.enabled;
  });
};
