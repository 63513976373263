import { PageStore } from './page';

export const getStoryMeta = (pageStore: PageStore): string => pageStore.state.server.storymeta || '';

export const getPageMiscData = (pageStore: PageStore) => {
  const { isSensitiveArticle: sensitiveArticle, sections, contentType } = pageStore.state.server;
  const { pageSection = '', pagesecondarySection = '' } = sections || {};
  const { type = '', subType = '' } = contentType || {};
  const pageType = pageSection ? `article: ${type}${subType ? `, ${subType}` : ''}` : 'home';
  const isHomePage = pageType === 'home';
  const channel = isHomePage ? pageType : pageSection.toLowerCase();

  return {
    pageType,
    channel,
    ...(!isHomePage && {
      sensitiveArticle,
      subSectionOne: pageSection.toLowerCase(),
      subSectionTwo: pagesecondarySection.toLowerCase(),
    }),
  };
};

export const getSubType = (pageStore: PageStore) => {
  const { contentType: { subType = '' } = {} } = pageStore.state.server;
  return subType;
};

export default getStoryMeta;
