import { isBoolean, has } from 'lodash';
import { MastheadWebstripAdData, SkinAdData } from '@/commercial/types';
import { CustomAdFormatStore } from './customAdFormats';

export const skinAdSignalReady = (customAdFormatsStore: CustomAdFormatStore): boolean => {
  const { isStickyTop, slots } = customAdFormatsStore.state.client.skinAd.skinAdvertData;
  return (isBoolean(isStickyTop) && has(slots, 'left') && has(slots, 'right') && has(slots, 'top')) || false;
};

export const skinAdData = (customAdFormatsStore: CustomAdFormatStore): SkinAdData =>
  customAdFormatsStore.state.client.skinAd;

export const hasSkinAd = (customAdFormatsStore: CustomAdFormatStore): boolean | null =>
  customAdFormatsStore?.state?.client?.skinAd?.isSkinAd;

export const isFirstPartySkin = (customAdFormatsStore: CustomAdFormatStore): boolean | null =>
  customAdFormatsStore?.state?.client?.skinAd?.isFirstParty;

export const mastheadWebstripData = (customAdFormatsStore: CustomAdFormatStore): MastheadWebstripAdData =>
  customAdFormatsStore.state.client.mastheadWebStripAd;

export const isMastheadWebStripAd = (customAdFormatsStore: CustomAdFormatStore): boolean | null =>
  customAdFormatsStore?.state?.client?.mastheadWebStripAd?.isMastheadWebStripAd;

export const checkCustomFooterAd = (customAdFormatsStore: CustomAdFormatStore): boolean =>
  !!customAdFormatsStore.state.client.customFooterAd.isCustomFooterAd;

export const getCustomFooterAdHeight = (customAdFormatsStore: CustomAdFormatStore): number =>
  customAdFormatsStore.state.client.customFooterAd.footerAdHeight;
