import { getWindowLocation } from '@/helpers/utils/dom/windowLocation';
import { getHashParam } from '@/helpers/utils/dom/locationHelpers';
import type { VideoAdRequestURLProps } from './types';

type CustomParams = [string, string | string[] | number][];
/**
 * https://support.google.com/admanager/answer/1080597?sjid=12666613855418784445-EU
 * Character  Encoded value
 *     =	        %3D
 *     &	        %26
 *     ,	        %2C
 *
 * Before encode: ttID=Lg1BUtxa&plID=mtr4BKa5
 * After encode: ttID%3DLg1BUtxa%26plID%3Dmtr4BKa5
 *
 */
const encodeGoogleKeyValues = (params: CustomParams): string => {
  const withStringifiedArrays = params.map(([key, val]) => {
    let newVal = val;
    if (Array.isArray(val)) {
      newVal = val.join('%2C');
    }
    return [key, newVal];
  });
  const keyValStrings = withStringifiedArrays.map((pair) => pair.join('%3D'));
  return keyValStrings.join('%26');
};

const getSiteData = (): CustomParams => {
  const site = 'navigator';
  const { pathname } = getWindowLocation();
  const articleId = pathname?.split('-').pop() ?? '';
  return [
    ['site', site],
    ['oid', articleId],
  ];
};
const getVideoPlayerData = (props: VideoAdRequestURLProps): CustomParams => {
  const [playerWidth, playerHeight] = props.currentSize;
  const videoEnvironment: string = 'Brightcove_Trinity Mirror';
  return [
    ['ttID', props.videoId],
    ['videoTitle', props.videoHeadline],
    ['video_environment', videoEnvironment],
    ['plID', props.playerId],
    ['playerWidth', String(playerWidth)],
    ['playerHeight', String(playerHeight)],
    ['vidIndex', String(props.videoIndex)],
    ['video_interaction', props.interaction],
    ['ppid', props.mantisId],
  ];
};
const getTargetingToken = (): CustomParams => {
  const token = getHashParam('token');
  return token ? [['token', token]] : [];
};

const getStorymetaData = (props: VideoAdRequestURLProps): CustomParams =>
  props.storymeta ? [['storymeta', props.storymeta]] : [];

const getMantisData = (props: VideoAdRequestURLProps): CustomParams =>
  props.mantisData
    ? [
        ['mantis', props.mantisData.mantis],
        ['iab_context', props.mantisData.iabContext],
        ['mantis_context', props.mantisData.mantisContext],
      ]
    : [];

const getSafetyMetaData = (props: VideoAdRequestURLProps): CustomParams => {
  const { brandSafety = '' } = props;
  const safetyMeta = brandSafety?.split(' ')[0];
  return safetyMeta ? [['safetymeta', safetyMeta]] : [];
};

const getVideoAuctionTargeting = (props: VideoAdRequestURLProps): CustomParams => {
  const { videoAuctionTargeting = {} } = props;
  return Object.entries(videoAuctionTargeting);
};

/**
 * TO DO: gs_cat, safetymeta, and aud are blocked now
 * custom targeting parameters must be added as an encoded string, for page and slot level targeting
 */
const getCustomParams = (props: VideoAdRequestURLProps): string => {
  const customParams: CustomParams = [
    ...getSiteData(),
    ...getVideoPlayerData(props),
    ...getTargetingToken(),
    ...getMantisData(props),
    ...getSafetyMetaData(props),
    ...getStorymetaData(props),
    ...getVideoAuctionTargeting(props),
  ];
  return encodeGoogleKeyValues(customParams);
};

const generateAdRequestURL = (props: VideoAdRequestURLProps): URL => {
  const descriptionUrl = getWindowLocation().href ?? '';
  const adRequestUrl = new URL('https://pubads.g.doubleclick.net/gampad/ads');
  // 640x480 (actually showing up as ‘640x480v’ in reports) is the creative size in the ad server, used to target the lines and creatives
  adRequestUrl.searchParams.append('sz', '640x480');
  adRequestUrl.searchParams.append('iu', props.videoAdUnitPath);
  adRequestUrl.searchParams.append('impl', 's');
  adRequestUrl.searchParams.append('gdfp_req', '1');
  adRequestUrl.searchParams.append('env', 'vp');
  adRequestUrl.searchParams.append('output', 'vast');
  adRequestUrl.searchParams.append('unviewed_position_start', '1');
  adRequestUrl.searchParams.append('description_url', descriptionUrl);
  adRequestUrl.searchParams.append('correlator', '__random-number__');
  adRequestUrl.searchParams.append('cust_params', getCustomParams(props));
  return adRequestUrl;
};

export default generateAdRequestURL;
